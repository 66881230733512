import Vue from 'vue' //引入Vue
import Router from 'vue-router' //引入vue-router

Vue.use(Router) //Vue全局使用Router

let router = new Router({
    mode: 'hash',
    base: '/',
    routes: [
        // 首页
        {
            path: '/',
            name: 'home',
            meta: {
                title: '枫叶租车',
                keepAlive: true
            },
            component: () =>
                import ("./view/home/home.vue"),
        },
        // 自驾租车
        {
            path: '/carList',
            name: 'carList',
            meta: {
                title: '自驾租车'
            },
            component: () =>
                import ("./view/carList/carList.vue"),
        },
        // 枫行世界
        {
            path: '/newList',
            name: 'newList',
            meta: {
                title: '枫行世界'
            },
            component: () =>
                import ("./view/newList/newList.vue"),
        },
        // 枫行世界
        {
            path: '/newListChildren',
            name: 'newListChildren',
            meta: {
                title: '枫行世界'
            },
            component: () =>
                import ("./view/newListChildren/newListChildren.vue"),
        },
        // 走进枫叶
        {
            path: '/companyProfile',
            name: 'companyProfile',
            meta: {
                title: '走进枫叶'
            },
            component: () =>
                import ("./view/companyProfile/companyProfile.vue"),
        },
        // 服务协议
        {
            path: '/agreement',
            name: 'agreement',
            meta: {
                title: '服务协议'
            },
            component: () =>
                import ("./view/agreement/agreement.vue"),
        },
        // 常见问题
        {
            path: '/problem',
            name: 'problem',
            meta: {
                title: '常见问题'
            },
            component: () =>
                import ("./view/problem/problem.vue"),
        },
    ],
})


export default router;