import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from '../../store/store'
import axios from "axios"; //打包解开 不打包注释掉
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../../assets/public.css'
//swiper
import 'swiper/swiper-bundle.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import Swiper2, { Autoplay } from 'swiper';

Swiper2.use([Autoplay]);
import 'fullpage.js/vendors/scrolloverflow';
import VueFullpage from 'vue-fullpage.js'
import FunPublic from '../../assets/public.js'
import Video from 'video.js'
import 'video.js/dist/video-js.css'

//引入样式才会生效
Vue.prototype.$video = Video;


// axios.defaults.baseURL = 'https://simu-pcapi.fyrentcar.com/' //打包解开 不打包注释掉(预生产)
axios.defaults.baseURL = 'https://testpcapi.fyrentcar.com/' //打包解开 不打包注释掉（本地测试）
// axios.defaults.baseURL = 'https://pc-api.fyrentcar.com/' //打包解开 不打包注释掉（生产环境）

router.beforeEach((to, from, next) => {
    /*监听有关滚动条问题*/
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
Vue.use(VueFullpage)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.FunPublic = FunPublic

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#home')